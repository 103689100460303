import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Hamburger from 'hamburger-react';
import axios from 'axios';
import '../App.css';

const URL = "https://aiofloci.com";

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          const response = await axios.get(`${URL}/user`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          setUsername(response.data.username);
        }
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    fetchUsername();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // Close the menu when the user scrolls
      if (menuOpen) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('scroll', handleScroll);
    };
  }, [menuOpen]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setUsername('');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav>
      <div className="menu-icon">
        <Hamburger toggled={menuOpen} toggle={toggleMenu} />
      </div>
      <ul className={`menu ${menuOpen ? 'open' : ''}`}>
        <li><NavLink to="/" onClick={closeMenu}>Home</NavLink></li>
        <li><NavLink to="/about" onClick={closeMenu}>About</NavLink></li>
        {username ? (
          <>
            <li>Welcome, {username}!</li>
            <li><button onClick={handleLogout} className='logout-button'>Logout</button></li>
          </>
        ) : (
          <>
            <li><NavLink to="/login" onClick={closeMenu} className="login-button">Login</NavLink></li>
            <li><NavLink to="/register" onClick={closeMenu} className="signup-button">Sign Up</NavLink></li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;
